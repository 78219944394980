import React from 'react';
import { MAIN_CONFIG } from '../../../config/main';

const Warning = ({ id, className, color = MAIN_CONFIG.COLORS.fillYellow, width = "16", height = "20", iconColor = MAIN_CONFIG.COLORS.fillBlack }) => {
    return (
        <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 20">
            <title>Warning</title>
            <desc>Warning</desc>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g transform="translate(-450 -723) translate(431 723) translate(19)">
                            <circle cx="8" cy="10" r="8" fill={color} />
                            <text fill={iconColor} fontFamily="Inter-Bold, Inter" fontSize="11" fontWeight="bold" letterSpacing=".917">
                                <tspan x="6.257" y="14">!</tspan>
                            </text>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
export default Warning;