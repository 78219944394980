import React, { useState, useEffect } from 'react';
import { ApplicationCard } from '../ApplicantApplication/ApplicationCard/ApplicationCard';
import './AppliedApplicationList.scss'
import CTAButton from '../../Components/CTAButton/CTAButton';
import '../../Components/ApplicantApplication/applicant-application.scss';
import Globe from '../../Components/SVG/Globe';
import { useRouter } from 'next/router';
import TagManager from 'react-gtm-module'
import { handleKeyDown } from '../../Utility/ApplicationFormUtility';
import { handleRedirectHrefUrl } from '../../Utility/HelperFunctions';
import { MAIN_CONFIG } from '../../../config/main';
import Close from '../SVG/Close';



export default ({
  color,
  secondaryColor,
  handleClose = () => { },
  setLoading = () => { },
  appliedApplication = [],
  scholarshipId,
  scholarship,
  applicationStepId,
  applicationForm=false
}) => {
  const router = useRouter();
  const handleRedirection = (application) => {

    let URL = `/application/${scholarship.sfid}/${application.sfid || application.heroku_id__c}`
    if (application.status__c === 'Submitted' || application.status__c === 'In Review Board') {
      URL = `/application/${scholarship.sfid}/${application.sfid || application.heroku_id__c}?action=completed`
    }
    return URL
  }


  const redirectApply = (url = '') => {
    setLoading(true)
    const data = {
      event: 'Begin',
      yourData: {
        event_category: "scholarship",
        event_label: scholarship.name,
        value: new Date()
      }
    };
    TagManager.dataLayer(data);

  // redirect to application 1st step
    handleRedirectHrefUrl(url)
  }

  return (
    <div id='applied-modal-content'>
      <div className="applied-head_container">
        <div className='H1DesktopGreen'>
        {scholarship.form_type__c === "Donation Form" ? "Donate Again" :   "Apply Again"}
        </div>
        <div className='apply-cancel_btn'>
        <button onClick={handleClose} onKeyDown={(e)=>handleKeyDown(e,()=>handleClose(e))} className="event-head cancel-icon H6DesktopGrey"><Close color={MAIN_CONFIG.COLORS.fillGrey} ariaLabel='Close' ariaLabelDescription='' /></button>
        </div>

      </div>

      <div className="applied-applications">


        <div className="applicant-applications__my-application">
          {
            appliedApplication.map((application, i) => {
              return <div >
                <ApplicationCard
                  logo={scholarship.scholarshiplogourl__c}
                  title={scholarship.name}
                  winner_date={application.winner_status_in_time__c}
                  submitted_date={application.submitted_status_date__c}
                  draft_date={application.draft_status_in_time__c}
                  review_board_date={application.in_review_board_status_in_time__c}
                  status={application.status__c}
                  scholarshipStatus={scholarship.status__c}
                  progress={application.completion_percentage__c}
                  hideProgress={scholarship.hide_application_completion_percentage__c}
                  color={color}
                  secondaryColor={secondaryColor}
                  resumeURL={handleRedirection(application)}
                  applicantRecommendations={[]}
                  awardDate={scholarship.award_announcement__c || scholarship.deadline_date_time__c}
                  setLoading={setLoading}
                  scholarshipHeaderLogo = {scholarship.scholarship_header_logo_url__c}
                  isDonationForm = {scholarship.form_type__c === "Donation Form"}
                  hideApplicationStatusForApplicant={scholarship.hide_application_status_for_applicant__c}
                  nomineeFirstName={application.nominee_first_name__c}
					      	nomineeLastName={application.nominee_last_name__c}
                  ShowNomineeNameInCard={scholarship.show_nominee_name_in_card__c}
                  daysRemaining={scholarship.day_remaining}
                  scholarshipDeadlineDate={scholarship.deadline_date_time}    
                />
              </div>
            })
          }

        </div>
      </div>

      <div className="applied-footer-container">
        <CTAButton id='apply-page__cta_close' onClick={handleClose} type="inverse" outline>
          Cancel
        </CTAButton>
          <CTAButton id='apply-page__cta' onClick={(e) => redirectApply(`/apply/${scholarship.sfid}/${applicationStepId}`)}>
            {scholarship.form_type__c === "Donation Form" ? "Donate Again" :   "Apply Again"}
          </CTAButton>
      </div>
    </div>
  )
}