import { scholarshipStatusData, scholarshipMainTextData, userMessageStatusArr } from "../../../../config/main"

export const getScholarshipStatusMessage = (value, isDonationForm) => {
	if (isDonationForm) {
		return "Donated"
	} else {
		return scholarshipMainTextData[value]
	}


}

export const getScholarshipUserMessage = (value, deadline, isDonationForm) => {
	const scholarshipStatus = ["Under Review" , "Prototype" , "Draft" ,"Incomplete"]
	if (userMessageStatusArr.includes(value) && deadline !== null && new Date(deadline) < new Date()) {
		return isDonationForm ? "View your donation" : "View your application"
	} else {
		return scholarshipStatusData[value] + " " + (!scholarshipStatus.includes(value) ?  "Application" : "")
	}
}